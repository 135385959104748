import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";

import LoginForm from "./login-form/login-form.jsx";

import "./../../../../Styles/site.css";

function IndexContainer() {
    return (
        <BrowserRouter>
            <Route path="/account/login" exact component={LoginForm} />
        </BrowserRouter>
    );
}

ReactDOM.render(
    <IndexContainer />,
    document.getElementById("root")
);
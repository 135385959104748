import React, { useState, useEffect } from "react";
import * as Icon from "@phosphor-icons/react";

import LoginTypeSelect from "./login-type-select.jsx";

import "./login-form.css";

import learnfulImage from "./../../../../../Assets/Images/common/learnful_purple.png";
import learnfulImageNoText from "./../../../../../Assets/Images/common/learnful-no-text-logo.png";

function LoginForm() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState({ id: 0 });
    const [returnUrl, setReturnUrl] = useState("");
    const [error, setError] = useState("");

    function logIn(event) {
        event.preventDefault();

        setError("");

        const valid = validFields();
        if (valid.valid) {
            const loginData = {
                username: username,
                password: password,
                returnUrl: function () {
                    let returnUrl = "";
                    const urlParams = new URLSearchParams(window.location.search);
                    const foundReturnUrl = urlParams.get("ReturnUrl");
                    if (foundReturnUrl != null)
                        returnUrl = foundReturnUrl;

                    return returnUrl;
                }()
            };

            fetch("/account/login", {
                method: "POST",
                body: JSON.stringify(loginData),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "dataType": "json"
                }
            })
            .then(response => response.json())
            .then(json => {
                if (json.user !== null) {
                    if (json.user.userTypes.length === 1)
                        window.location.href = json.returnUrl;
                    else {
                        setReturnUrl(json.returnUrl);
                        setUser(json.user);
                    }
                }
                else
                    setError(json.errorMessage);
            })
            .catch(error => console.log(error));
        }
        else {
            setError(valid.error);
        }
    }

    function validFields() {
        let isValid = true;

        let error = "";
        if (username.trim() === "") {
            error = "Please enter your username.";
            isValid = false;
        }
        else if (password.trim() === "") {
            error = "Please enter your password.";
            isValid = false;
        }

        return {
            valid: isValid,
            error: error
        };
    }

    return (
        <div styleName="main-container">
            {
                user.id === 0 ?
                    (
                        <div styleName="login-form layout">
                            <div styleName="section__login-display">
                                <img src={learnfulImageNoText} styleName="learnful-image-no-text" />
                            </div>

                            <div styleName="section__login-form">
                                <img src={learnfulImage} styleName="learnful-image" />
                                <div styleName="login-msg">Welcome! <Icon.HandWaving styleName="hand-icon" weight="fill" /></div>
                                <form styleName="login-form__form" onSubmit={logIn}>
                                    <input type="text" styleName="login-form__input-text" value={username} onChange={e => setUsername(e.target.value)} id="username" name="username" placeholder="Username" required autoFocus />
                                    <input type="password" styleName="login-form__input-text" value={password} onChange={e => setPassword(e.target.value)} name="password" placeholder="Password" required />
                                    <button type="submit" styleName="login-form__button login-form__button--highlight">Log In</button>
                                </form>
                                <div styleName="forgot-link-container">
                                    <a href="/account/forgot" styleName="forgot-link">Forgot username or password?</a>
                                </div>
                                <div styleName="error-msg">
                                    {error}
                                </div>
                            </div>
                        </div>
                    ) :
                    (
                        user.userTypes.length > 1 &&
                        <LoginTypeSelect userTypes={user.userTypes}
                            returnUrl={returnUrl}
                        />
                    )
            }
        </div>
    );
}

export default LoginForm
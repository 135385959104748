import React, { useState, useEffect, Fragment } from "react";
import * as Icon from "@phosphor-icons/react";

import "./../../../../../Styles/form.css";
import "./login-type-select.css";
import learnfulImage from "./../../../../../Assets/Images/common/learnful_purple.png";

function LoginTypeSelect(props) {
    const [selectedUserType, setSelectedUserType] = useState("");

    useEffect(validateUserTypes, []);

    function validateUserTypes() {
        if (props.userTypes.length === 0) {
            fetch("/account/logout", {
                method: "POST",
                body: JSON.stringify(""),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "dataType": "json"
                }
            })
            .then(response => response.json())
            .catch(error => console.log(error));
        }
    }

    function logInAs(event) {
        event.preventDefault();

        if (validFields()) {
            const userTypeData = {
                userTypeName: selectedUserType,
                redirectUrl: props.returnUrl
            };

            fetch("/account/currentusertype", {
                method: "POST",
                body: JSON.stringify(userTypeData),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "dataType": "json"
                }
            })
            .then(response => response.json())
            .then(json => window.location.href = json.redirectUrl)
            .catch(error => console.log(error));
        }
        else {
        }
    }

    function validFields() {
        let isValid = true;

        let error = "";
        if (selectedUserType === "") {
            error = "Please select a user type to log in as.";
            isValid = false;
        }

        return isValid;
    }

    return (
        <div styleName="login-type-select">
            {
                props.userTypes.length > 0 ?
                    (
                        <div styleName="login-type-select--multiple">
                            <img src={learnfulImage} styleName="learnful-image" />
                            <div><Icon.UserSwitch styleName="select-icon" weight="fill" /></div>
                            <div styleName="login-type-select-title">Select User Type</div>
                            <span styleName="login-type-select__msg--multiple">It seems your account is associated with multiple user types, which one would you like to log in as right now? You can switch later at anytime.</span>
                            <form onSubmit={logInAs}>
                                <select styleName="field-container__input-select--long login-type-select__role" name="userTypeName" id="userTypeName" value={selectedUserType} onChange={(e) => setSelectedUserType(e.target.value)}>
                                    <option disabled value="">- Log in as -</option>
                                    {
                                        props.userTypes.map((ut, index) => {
                                            return (
                                                <option key={index} value={ut}>{ut}</option>
                                            )
                                        })
                                    }
                                </select>
                                <button type="submit" styleName="login-form__button login-form__button--highlight">Go</button>
                            </form>
                        </div>
                    ) :
                    (
                        <div styleName="login-type-select--none">
                            <span styleName="login-type-select__msg--none">It seems your user account is not associated with any user type, preventing this log in. Please contact the school or an administrator.</span>
                        </div>
                    )
            }
        </div>
    );
}

export default LoginTypeSelect